<template>
  <transition :appear="true" name="slide">
    <div class="answer-rank" :style="head_st">
      <div class="header" >
        <div class="back" @click="back">
          <div class="icon"></div>
          <div class="name">返回</div>
        </div>
        <div class="tab"></div>
      </div>
      <scroll
        ref="scroll"
        class="scroll"
        :data="list"
      >
        <div>
          <div class="rank-list" v-show="tabIndex === 0">
            <div class="rank-item">
              <div class="sort title">排名</div>
              <div class="name title">用户</div>
              <div class="num title">答题数</div>
              <div class="time title">答题时间</div>
            </div>
            <template v-for="(item,index) in list">
              <div class="rank-item" :key="index">
                <div class="sort">
                  <div
                    :class="{first: index + 1 === 1, second: index + 1 === 2, third: index + 1 === 3}"
                    v-if="index + 1 === 1 || index + 1 === 2 || index + 1 === 3"
                  ></div>
                  <span v-else>{{ index + 1 }}</span>
                </div>
                <div class="name">{{ item.c_user_wxname }}</div>
                <div class="num">{{ item.rank_correct_num }}</div>
                <div class="time">{{ item.rank_time_cost }}</div>
              </div>
            </template>
          </div>
          <div class="rank-list" v-show="tabIndex === 1">
            <div class="a-rank" v-for="(item,index) in aList" :key="index">
              <div class="sort">
                <div
                  :class="{first: index === 0, second: index === 1, third: index === 2}"
                  v-if="index === 0 || index === 1 || index === 2"
                ></div>
                <div class="sort-num" v-else>{{ index + 1}}</div>
              </div>
              <div class="rank-info">
                <div class="a-name">{{ item.agency_name }}</div>
                <div class="a-num">参与读者数量：<span
                  style="color: rgba(233, 58, 58, 1);">{{ item.rank_c_user_num }}</span>人
                </div>
                <div class="a-num">参与答题数量：<span
                  style="color: rgba(233, 58, 58, 1);">{{ item.rank_correct_total_num }}</span>道
                </div>
              </div>
            </div>
          </div>
        </div>
      </scroll>
      <div class="rank-list self-rank">
        <div class="rank-item">
          <div class="sort self">{{ self.sort }}</div>
          <div class="name self">{{ self.c_user_wxname }}</div>
          <div class="num self">{{ self.rank_correct_num }}</div>
          <div class="time self">{{ self.rank_time_cost }}</div>
        </div>
      </div>
      <!-- <div class="bottom">本活动最终解释权归内蒙古自治区图书馆所有</div> -->
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { agencyRank, rank } from '@/api/Questions'
import { mapGetters } from 'vuex'
import { getCurrentConnect } from '@/api/Activity'

export default {
  name: 'AnswerRank',
  components: {
    Scroll
  },
  computed: {
    ...mapGetters(['userGuid', 'agencyGuid'])
  },
  data () {
    return {
      tab: ['读者排名', '图书馆排名'],
      tabIndex: 0,
      list: [],
      self: {},
      aList: [],
      head_st: '',
      activity: localStorage.getItem('activity') ? JSON.parse(localStorage.getItem('activity')) : {},
      activity_guid: ''
    }
  },
  created () {
    if (this.activity.activity_guid) {
      this.activity_guid = this.activity.activity_guid
      this.head_st = 'background-image:url(' + this.activity.activitySetting.activity_rank_bg_img + ')'
    } else {
      this._getCurrentConnect()
    }
  },
  mounted () {
    this._rank()
    this._agencyRank()
  },
  methods: {
    _getCurrentConnect () {
      let _this = this
      getCurrentConnect({ activity_type_new: 3, c_user_guid: this.userGuid, agency_guid: this.agencyGuid, activity_guid: this.activity_guid }).then(res => {
        if (res.code === 200) {
          _this.head_st = 'background-image:url(' + res.data.activitySetting.activity_rank_bg_img + ')'
        }
      })
    },
    back () {
      let index = this.$route.path.indexOf('/answer-rank')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    _rank () {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      rank({
        c_user_guid: this.userGuid,
        activity_guid: this.activity_guid
      }).then(res => {
        const {
          code,
          data,
          msg
        } = res
        if (code === 200) {
          this.list = data.list
          this.self = data.self
          this.$toast.clear()
        } else {
          this.$toast.fail(msg)
        }
        this.refresh()
      })
    },
    _agencyRank () {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      agencyRank({ c_user_guid: this.userGuid, activity_guid: this.activity_guid }).then(res => {
        const {
          code,
          data,
          msg
        } = res
        if (code === 200) {
          this.aList = data
          this.$toast.clear()
        } else {
          this.$toast.fail(msg)
        }
        this.refresh()
      })
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  },
  watch: {
    tabIndex (index) {
      if (index === 0) {
        this._rank()
      } else if (index === 1) {
        this._agencyRank()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.answer-rank
  position absolute
  z-index 1000
  top 0
  left 0
  right 0
  bottom 0
  background-repeat:no-repeat;
  background-size:100% 100%;

  .header
    position relative
    width 750px
    height 416px
    background-size 754px 100%
    background-position center
    background-repeat no-repeat

    .back
      display flex
      align-items center
      width 160px
      height 64px

      .icon
        width 16px
        height 28px
        margin-left 43px
        background-image:url('~@/assets/img/reading/back.png')
        background-size 100% 100%
        background-repeat no-repeat

      .name
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color #111
        margin-left 10px

    .tab
      position absolute
      bottom 0
      left 50%
      transform translate(-50%, 0)
      display flex
      width: 706px;
      height: 55px;
      border-radius: 14px;
      background-image:url('~@/assets/img/activity/banner.png');
      background-repeat:no-repeat;
      background-size:100% 100%;
      .tab-item
        position relative
        display flex
        align-items center
        justify-content center
        width 50%
        height 100%

        &:first-child
          .ico
            bg-image2('msyq-question/reader20230412')

        &:last-child
          .ico
            bg-image2('msyq-question/library20230412')

        &.active::after
          position absolute
          bottom 0
          left 50%
          transform translate(-50%, 0)
          content ''
          width 118px
          height 8px
          background rgba(255, 255, 255, 1)

        .ico
          width 50px
          height 50px
          background-size 100% 100%
          background-repeat no-repeat

  .scroll
    position absolute
    top 406px
    left 44px
    right 44px
    bottom 164px
    background rgba(254, 255, 247, 1)
    overflow hidden

  .rank-list
    width 100%

    .rank-item
      display flex
      align-items center
      justify-content space-around
      width 620px
      height 78px
      margin 0 auto
      border-bottom 1px solid rgba(234, 235, 228, 1)

      &:first-child
        border-bottom none

      &:last-child
        border-bottom none

      .sort
        display flex
        justify-content center
        width 80px
        font-size 26px
        color rgba(106, 106, 106, 1)

        .first
          width 32px
          height 40px
          bg-image2('msyq-question/first-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .second
          width 32px
          height 40px
          bg-image2('msyq-question/second-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .third
          width 32px
          height 40px
          bg-image2('msyq-question/third-ico')
          background-size 100% 100%
          background-repeat no-repeat

      .name
        width 180px
        text-align center
        font-size 26px
        color rgba(106, 106, 106, 1)
        no-wrap()

      .num
        width 120px
        text-align center
        font-size 26px
        color rgba(255, 52, 22, 1)
        no-wrap()

      .time
        width 140px
        text-align center
        font-size 26px
        color rgba(255, 52, 22, 1)
        no-wrap()

      .title
        font-size 30px
        color rgba(51, 51, 51, 1)

      .self
        color rgba(255, 52, 22, 1)

    .a-rank
      display flex
      width 628px
      height 140px
      margin 0 auto
      padding 30px 0
      box-sizing border-box
      border-bottom 1px solid rgba(235, 230, 226, 1)
      &:last-child
        border-bottom none
      .sort
        display flex
        justify-content center
        width 48px
        font-size 26px
        color rgba(106, 106, 106, 1)

        .first
          width 32px
          height 40px
          bg-image2('msyq-question/first-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .second
          width 32px
          height 40px
          bg-image2('msyq-question/second-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .third
          width 32px
          height 40px
          bg-image2('msyq-question/third-ico')
          background-size 100% 100%
          background-repeat no-repeat
        .sort-num
          display flex
          align-items center
          justify-content center
          width 38px
          height 38px
          font-size 28px
          color rgba(255, 255, 255, 1)
          background rgba(233, 58, 58, 1)
          border-radius 50%
      .rank-info
        display flex
        flex-wrap wrap
        width 580px
        height 100%
        .a-name
          width 100%
          font-size 28px
          color rgba(63, 63, 63, 1)
          no-wrap()
        .a-num
          width 50%
          margin-top 20px
          color rgba(106, 106, 106, 1)
          font-size 26px

  .self-rank
    position absolute
    left 44px
    right 44px
    bottom 86px
    width 662px
    height 78px
    padding-top 0
    border-radius 0 0 14px 14px
    background rgba(254, 255, 247, 1)

  .bottom
    position absolute
    z-index 1
    left 0
    bottom 0
    display flex
    justify-content center
    align-items center
    width 100%
    height 86px
    font-size 26px
    color rgba(255, 255, 255, 1)
</style>
